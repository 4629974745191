export default async function (e: Event): Promise<number | string> {
  if (!(e.target instanceof HTMLFormElement)) { return 400 }
  const formData = new FormData(e.target)
  try {
    const res = await $fetch('/api/signin', {
      method: 'POST',
      body: {
        username: formData.get('username'),
        password: formData.get('password')
      },
      redirect: 'manual'
    }).catch((err) => {
      return err.response
    })
    if (res instanceof Response) { return res.status }
  } catch (e) {
    if (e instanceof Error) { return e.message }
  }

  return 302
}
