<script setup lang="ts">
const user = useUser()
if (user.value?.roleId === 'ADMIN') {
  await navigateTo('/admin')
}
definePageMeta({
  title: 'auth.title'
})
</script>

<template>
  <div class="grid justify-items-center">
    <AuthLogin />
  </div>
</template>

<style scoped></style>
