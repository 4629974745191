<script setup lang="ts">
const loginModal = ref()

const loginResult = ref({
  title: '',
  description: ''
})
async function login (e: Event) {
  const result = await useLogin(e)
  if (result !== 302) {
    loginResult.value.title = 'auth.error.invalid.title'
    loginResult.value.description = 'auth.error.invalid.description'
    loginModal.value.showModal()
  } else {
    loginResult.value.title = ''
    loginResult.value.description = ''
    // redirect to admin page
    await navigateTo('/admin', {})
  }
}
</script>

<template>
  <form @submit.prevent="login">
    <div class="form-control join">
      <label class="label join join-vertical">
        <span class="label-text">{{ $t('auth.label.username') }}</span>
        <input
          name="username"
          type="text"
          :placeholder="$t('placeholder.username')"
          class="input input-bordered"
        >
      </label>
      <label class="label join join-vertical">
        <span class="label-text">{{ $t('auth.label.password') }}</span>
        <input
          name="password"
          type="password"
          :placeholder="$t('placeholder.password')"
          class="input input-bordered"
        >
      </label>
      <button class="btn btn-primary">
        {{ $t('rma.label.next') }}
      </button>
    </div>
  </form>
  <dialog ref="loginModal" class="modal">
    <div v-if="loginResult.title" class="modal-box">
      <form method="dialog">
        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
          ✕
        </button>
      </form>
      <h3 class="font-bold text-lg">
        {{ $t(loginResult.title) }}
      </h3>
      <p class="py-4">
        {{ $t(loginResult.description) }}
      </p>
    </div>
    <form method="dialog" class="modal-backdrop">
      <button>close</button>
    </form>
  </dialog>
</template>

<style scoped></style>
